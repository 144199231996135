import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

import NewContractForm from "../../../components/NewForm";
import { clearValues, createContract } from "./actions";
import {
	getHiredList,
	getCompanies,
	clearValues as clearHiredValues
} from "../../../../Company/containers/Report/actions";
import { replaceStringCurrencyToNumber } from "helpers/string";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import _ from "lodash";
import periodType from "../../../../../types/periodType";
import ls from "Localization";
import {
	contractStatus,
	contractStatusConst,
	contractTemplateTypes,
	signerStatus
} from "types/contractStatus";
import { getDepartmentList } from "../../ReportDepartment/actions";
import ContractList from "containers/Contracts/components/ContractList";
import { uploadFile } from "../EditAll/actions";

dayjs.extend(customParseFormat);

let findHirerList = null;
let findHiredList = null;
let getDepartmentDebounced = null;

const NewContract = ({ history }) => {
	const dispatch = useDispatch();

	const { loading, errors } = useSelector(s => s.newContract);
	const { items: contractList } = useSelector(s => s.contracts);
	const { items: companies, hireds } = useSelector(s => s.company);

	const { items: departmentList } = useSelector(s => s.departmentList);

	const contract_create = useSelector(s => s.form.contract_create);

	const [hirer, setHirer] = useState("");
	const [hiredId, setHiredId] = useState("");

	const onSubmit = async data => {
		dispatch(
			createContract(
				{
					hired: data.hired.value,
					mei: data.mei.value,

					name: data.name,
					type: data.type?.value,
					department: data.department?.value,

					externalId: data.externalId,
					status:
						data.status && data.status.value ? data.status.value : undefined,
					hirerToken: data.hirerToken,
					hirerStatus: data.hirerStatus ? data.hirerStatus.value : undefined,
					hiredToken: data.hiredToken,
					hiredStatus: data.hiredStatus ? data.hiredStatus.value : undefined,

					startDate: dayjs(data.startDate, "DD/MM/YYYY"),
					selfRenewable: data.selfRenewable,
					lifespan: data.lifespan,
					due: dayjs(data.due, "DD/MM/YYYY"),

					generateFinancial: data.generateFinancial,
					paymentValue: replaceStringCurrencyToNumber(
						"R$",
						data.paymentValue || "0"
					),
					paymentPeriod: data.paymentPeriod.value,
					paymentDate: dayjs(data.paymentDate, "DD/MM/YYYY"),
					paymentInterval: data.paymentInterval,

					isMainContract: true,

					note: data.note
				},
				(err, model) => {
					if (err) {
						if (err.code) {
							alert("1" + err.code);
						} else if (typeof err === "string") alert("2" + err);
						else if (err.errors && err.errors.default)
							alert(err.errors.default);
						else alert(JSON.stringify("3" + err));
					} else {
						if (data.file) {
							dispatch(
								uploadFile(model._id, data.file, err => {
									if (err) {
										if (typeof err === "string") {
											alert("4" + err);
										} else {
											alert("5" + JSON.stringify(err));
										}
									} else {
										history.push("/contracts");
									}
								})
							);
						} else {
							history.push("/contracts");
						}
					}
				}
			)
		);
	};

	const handleFindHirerList = businessName => {
		if (findHirerList) {
			findHirerList.cancel();
		}

		let filterStr = "&filters[isHired]=true";

		if (businessName) filterStr += `&filters[businessName]=${businessName}`;

		findHirerList = _.debounce(
			() =>
				dispatch(
					getCompanies(0, 10, filterStr, "createdAt", true, false, err => {
						if (err)
							alert(
								"Não foi possível carregar os dados, erro: " + typeof err ===
									"string"
									? err
									: JSON.stringify(err)
							);
					})
				),
			500
		);

		findHirerList();
	};

	const handleSelectHirer = el => {
		setHirer(el.value);
		handleFindHiredList(el.value);
	};

	const handleFindHired = el => handleFindHiredList(hirer, el);

	const handleSelectHired = el => {
		if (el.value) {
			setHiredId(el.value);
		}
	};

	const handleFindHiredList = (hirer_id, businessName = null) => {
		if (!hirer_id) {
			console.log("Escolha o contratante primeiro!");
			return;
		}

		if (findHiredList) {
			findHiredList.cancel();
		}

		let filterStr = "";

		if (businessName) filterStr += `&filters[businessName]=${businessName}`;

		findHiredList = _.debounce(
			() =>
				dispatch(
					getHiredList(hirer_id, filterStr, err => {
						if (err)
							alert(
								"Não foi possível carregar os dados, erro: " + typeof err ===
									"string"
									? err
									: "Erro: " + JSON.stringify(err)
							);
					})
				),
			500
		);

		findHiredList();
	};

	const handleFindDepartment = name => {
		if (getDepartmentDebounced) {
			getDepartmentDebounced.cancel();
		}

		let filterStr = "";

		if (name) filterStr += `&filters[name]=${name}`;

		getDepartmentDebounced = _.debounce(
			() =>
				dispatch(
					getDepartmentList(0, 10, filterStr, "createdAt", true, false, err => {
						if (err)
							alert(
								"Não foi possível carregar os dados, erro: " + typeof err ===
									"string"
									? err
									: JSON.stringify(err)
							);
					})
				),
			500
		);

		getDepartmentDebounced();
	};

	const periodTypeData = Object.keys(periodType).map(c => ({
		value: c,
		label: ls[periodType[c]]
	}));

	const departmentData = departmentList.map(c => ({
		value: c._id,
		label: c.name
	}));

	const typeData = Object.keys(contractTemplateTypes).map(c => ({
		value: c,
		label: ls[contractTemplateTypes[c]]
	}));

	const statusData = Object.keys(contractStatus).map(c => ({
		value: c,
		label: ls[contractStatus[c]]
	}));

	const signerStatusData = Object.keys(signerStatus).map(c => ({
		value: c,
		label: ls[signerStatus[c]]
	}));

	const initialValues = {
		paymentInterval: "1",
		paymentPeriod: periodTypeData[0],
		status: {
			value: contractStatusConst.AwaitingSignature,
			label: ls[contractStatus[contractStatusConst.AwaitingSignature]]
		}
	};

	useEffect(() => {
		dispatch(clearValues());
		dispatch(clearHiredValues());
		handleFindDepartment();
	}, []);

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<NewContractForm
								onSubmit={onSubmit}
								initialValues={initialValues}
								loading={loading}
								isInvalid={contract_create && !!contract_create.syncErrors}
								errors={errors}
								statusData={statusData}
								signerStatus={signerStatusData}
								periodTypeData={periodTypeData}
								typeData={typeData}
								departmentData={departmentData}
								meis={companies}
								hireds={hireds}
								contractList={contractList}
								onSearchMei={handleFindHirerList}
								onSelectMei={handleSelectHirer}
								onSearchHired={handleFindHired}
								onSelectHired={handleSelectHired}
								onSearchDepartment={handleFindDepartment}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>

			<Row>
				<Col>
					<ContractList
						title={"Lista Contratos do Prestador"}
						hiredId={hiredId}
					/>
				</Col>
			</Row>
		</Container>
	);
};

export default NewContract;
